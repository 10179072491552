export const timeStampConverter = (time: string | number | Date): string => {
  const date = new Date(time);
  const minute = String(date.getMinutes()).padStart(2, '0');
  const hour = date.getHours();
  const regExp = /\(([^)]+)\)/;
  const timezone = regExp
    .exec(date.toString())![1]
    .replace(/\s/g, '')
    .replace(/[^A-Z]+/g, '');
  return `${hour > 12 ? hour - 12 : hour}:${minute}${
    hour < 12 ? 'am' : 'pm'
  } ${timezone}`;
};


export function base64toBlob(base64Data: string) {
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays);
}
