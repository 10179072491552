import { get, method } from "lodash";

// export const apiBaseUrl = "http://localhost:8000/api/v1";
export const chatBotBaseUrl = process.env.REACT_APP_CHATBOT_API_URL;

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiMapper = {
  enquiryForm: {
    method: "POST",
    endpoint: `${apiBaseUrl}/enquiry/`,
  },
  intakeForm: {
    method: "POST",
    endpoint: `${apiBaseUrl}/intake/create`,
  },
  chatBotMessage: {
    method: "POST",
    endpoint: `${chatBotBaseUrl}/process_ques`,
  },
  rfpChatBotAPI: {
    method: "POST",
    endpoint: `${chatBotBaseUrl}/process_rfp`,
  },
  clearSessionAPI: {
    method: "POST",
    endpoint: `${chatBotBaseUrl}/clear_session`,
  },
  getAllArchetype: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/archetypes`,
  },
  getAllPlatform: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/technologies`,
  },
  getAllSector: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/sectors`,
  },
  getAllAssets: {
    method: "GET",
    endpoint: `${apiBaseUrl}/asset/list`,
  },
  getAllEnquiryRequestType: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/enquiry-request-types`,
  },
  searchFilter: {
    method: "GET",
    endpoint: `${apiBaseUrl}/search/filters`,
  },
  searchCapability: {
    method: "POST",
    endpoint: `${apiBaseUrl}/search/capabilities`,
  },
  asset: {
    method: "GET",
    endpoint: `${apiBaseUrl}/asset`,
  },
  getUserDetails: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/loggedin-user-details`,
  },
  getEnquiryList: {
    method: "GET",
    endpoint: `${apiBaseUrl}/enquiry/get-all-enquiries`,
  },
  getAllEnquiryStatus: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/enquiry-statuses`,
  },
  getEnquiryDetails: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/enquiry/get-enquiry-details`,
  },
  updateEnquiry: {
    method: "PUT",
    endpoint: `${apiBaseUrl}/enquiry`,
  },
  getAIDataCategory: {
    method: "GET",
    endpoint: `${apiBaseUrl}/category/aidata`,
  },
  getSdlcCategory: {
    method: "GET",
    endpoint: `${apiBaseUrl}/category/sdlc`,
  },
  getArchetypeCategory: {
    method: "GET",
    endpoint: `${apiBaseUrl}/category/archetype`,
  },
  getTabItem: {
    method: "GET",
    endpoint: `${apiBaseUrl}/category/`,
  },
  getAllIntakeRequestType: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/intake-request-types`,
  },
  getIntakeList: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/intake/get-all-intake`,
  },
  getAllIntakeStatus: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/intake-statuses`,
  },
  getIntakeDetails: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/intake`,
  },
  updateIntake: {
    method: "PUT",
    endpoint: `${apiBaseUrl}/admin/intake`,
  },
  submitFeedback: {
    method: "POST",
    endpoint: `${apiBaseUrl}/utils/feedback`,
  },
  deleteEnquiry: {
    method: "DELETE",
    endpoint: `${apiBaseUrl}/admin/enquiry/delete-enquiry`,
  },
  getAllBusinessUnit:{
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/all-business-units`,
  },
  getEnquiryTimeline: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/enquiry/get-enquiry-timeline`,
  },
  getInsightDashboard: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/insight/dashboard`,
  },
  getCapabilityDetails: {
    method: "GET",
    endpoint: `${apiBaseUrl}/asset/get-capability-deatils`,
  },
  getAllAssetWrapper: {
    method: "GET",
    endpoint: `${apiBaseUrl}/utils/get-all-asset-wrapper`,
  },
  getIntakeComments: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/intake/comment`,
  },
  addIntakeComments: {
    method: "POST",
    endpoint: `${apiBaseUrl}/admin/intake/comment`,
  },
  insightExport: {
    method: "GET",
    endpoint: `${apiBaseUrl}/admin/insight/insight-export` ,
  },
  getAllBluePrint: {
    method: "GET",
    endpoint: `${apiBaseUrl}/digital-blueprint/all-blueprint`,
  },
  getBluePrintDetails: {
    method: "GET",
    endpoint: `${apiBaseUrl}/digital-blueprint/blueprint`,
  },
  logDigitalBlueprintClick: {
    method: "POST",
    endpoint: `${apiBaseUrl}/digital-blueprint/blueprint-activity`,
  }
};
