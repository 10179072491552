import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React from "react";
import { IconCloudDownload } from "@tabler/icons-react";
import { saveAs } from "file-saver";
import _ from "lodash";

import { theme } from "../../utils/theme";
import ImgMapper from "../../utils/document-img-mapper";
import { base64toBlob } from "../Chatbot/timeUtils";

type Props = {
  attachments: { file: string; fileType: string; fileName: string }[];
};

export default function IncomingFileView({ attachments }: Props) {
  const handleDownload = (attachment: {
    file: string;
    fileType: string;
    fileName: string;
  }) => {
    saveAs(
      base64toBlob(attachment.file),
      `${attachment.fileName}.${attachment.fileType}`
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
        my: 1,
      }}
    >
      {attachments.map((attachment, index) => (
        <Card
          sx={{
            display: "flex",
            flexGrow: 1,
            border: 1,
            borderColor: theme.palette.grey[200],
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            p: 0,
            cursor: "pointer",
            position: "relative",
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              background: "white",
              padding: "8px",
              pb: "8px !important",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems={"center"}
              gap={1}
              justifyContent={"space-between"}
            >
              <Box display="flex" gap={1} alignItems={"center"}>
                <img
                  src={ImgMapper[attachment.fileType]}
                  alt="Deloitte"
                  width={attachment.fileType === "xlsx" ? 30 : 25}
                  height={attachment.fileType === "xlsx" ? 32 : 30}
                  style={{
                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",

                    marginTop:
                      attachment.fileType === "xlsx" ? "-5px" : undefined,
                    WebkitFilter: "drop-shadow(2px 2px 2px rgba(0,0,0,0.3))",
                    filter: "drop-shadow(2px 2px 2px rgba(0,0,0,0.3))",
                  }}
                />

                <Typography
                  variant={"body2"}
                  fontWeight={"bold"}
                  sx={{
                    whiteSpace: "wrap",
                    width: "190px",
                  }}
                >
                  {_.capitalize(attachment.fileName)}
                </Typography>
              </Box>
              <Box
                sx={{
                  pl: 0.5,
                  borderLeft: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                <IconCloudDownload
                  size={20}
                  className="button-download-icon"
                  onClick={() =>
                    handleDownload({
                      file: attachment.file,
                      fileType: attachment.fileType,
                      fileName: attachment.fileName,
                    })
                  }
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
