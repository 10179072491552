import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tab, Tabs, Typography } from "@mui/material";
import { IconPaperclip, IconSend2 } from "@tabler/icons-react";
import axios from "axios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import { nanoid } from "nanoid";
import { ReactEventHandler, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { apiMapper } from "../../utils/api-mapper";
import chat from "./../../assets/images/chat.png";
import classes from "./ChatAssistant.module.sass";
import IncomingFileView from "./IncomingFileView";

type SessionInfo = {
  sessionId: string;
  sessionType: "RFP" | "ASSETS";
  sessionTime: dayjs.Dayjs;
};

interface Message {
  content: string;
  firstMsg: boolean;
  createdDateTime: Date;
  messageType: "SERVER" | "USER";
  attachments?: { file: string; fileType: string, fileName: string }[];
}

export const ChatAssistant = (props: {
  handleChatClose: ReactEventHandler;
  expandModal: ReactEventHandler;
}) => {
  const [messageInput, setMessageInput] = useState("");
  const [enter, setEnter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isRFPAPI, setisRFPAPI] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<SessionInfo>({
    sessionId: nanoid(),
    sessionType: "ASSETS",
    sessionTime: dayjs(),
  });

  const [messageList, setMessageList] = useState<Message[]>([]);

  const uploadFileInputRef = useRef<any>(null);

  const chatInput = useRef<FormData>(new FormData());

  const accessToken = useRef(
    localStorage.getItem("tokenResponse")
      ? jwtDecode(
          JSON.parse(localStorage.getItem("tokenResponse") as string)
            ?.idToken || null,
        )
      : null,
  );

  const switchSession = (value: boolean) => {
    setMessageList([]);
    setEnter(false);
    setisRFPAPI(value);
    setSessionId({
      sessionId: nanoid(),
      sessionType: value ? "RFP" : "ASSETS",
      sessionTime: dayjs(),
    });
  };

  const [activeTab, setActiveTab] = useState(0);

  const onFileUpload = async (e: any) => {
    if (isLoading) return;

    const file = e.target.files[0];

    if (!file.name.match(/\.(pdf|doc|docx)$/)) {
      alert("Please upload a PDF or Word document.");
      return;
    }

    setEnter(true);

    addMessageToList({
      content: "Document is being analysed. Please wait for the response.",
      firstMsg: true,
      createdDateTime: new Date(),
      messageType: "USER",
    });

    setIsLoading(true);
    chatInput.current.append("file", file);

    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "user_id",
      (accessToken.current as any)?.preferred_username,
    );
    await checkSession();
    formData.append("session_id", sessionId.sessionId);
    const response = await axios.request({
      method: apiMapper.rfpChatBotAPI.method,
      url: apiMapper.rfpChatBotAPI.endpoint,
      data: formData,
    });

    if (response.status === 200) {
      const data =
        typeof response?.data === "string"
          ? response?.data
          : response?.data?.response ||
            "There is some issue in processing the document. Please try again.";
      console.log(response);
      if (data !== "") {
        addMessageToList({
          content: data,
          firstMsg: false,
          createdDateTime: new Date(),
          messageType: "SERVER",
          attachments: response?.data?.response?.attachments || []
        });
      }
    } else {
      addMessageToList({
        content:
          "There is some issue in processing the document. Please try again.",
        firstMsg: false,
        createdDateTime: new Date(),
        messageType: "SERVER",
        attachments: []
      });
    }

    setIsLoading(false);
  };

  const onTriggerFileUpload = () => {
    setisRFPAPI(true);
    uploadFileInputRef.current.click();
  };

  const addMessageToList = (value: any) => {
    if (value.room === "") return;
    setMessageList((messageList: any[]) => [...messageList, value]);
  };

  const checkSession = async () => {
    if (dayjs().diff(sessionId.sessionTime, "minute") > 15) {
      setSessionId({
        sessionId: nanoid(),
        sessionType: sessionId.sessionType,
        sessionTime: dayjs(),
      });

      const response = await axios.request({
        method: apiMapper.clearSessionAPI.method,
        url: apiMapper.clearSessionAPI.endpoint,
        data: {
          session_id: sessionId.sessionId,
          user_id: (accessToken.current as any)?.preferred_username,
        },
      });

      if (response.status !== 200) {
        alert("Session expired. Error while clearing session.");
      }
      switchSession(isRFPAPI);
    } else {
      setSessionId({
        sessionId: sessionId.sessionId,
        sessionType: sessionId.sessionType,
        sessionTime: dayjs(),
      });
    }
  };

  const fetchMessages = async () => {
    if (messageInput != "") {
      addMessageToList({
        content: messageInput,
        firstMsg: false,
        createdDateTime: new Date(),
        messageType: "USER",
      });
    }
    chatInput.current.append("message", messageInput);
    setIsLoading(true);
    setMessageInput("");
    const formData = new FormData();
    formData.append("user_query", messageInput);
    formData.append(
      "user_id",
      (accessToken.current as any)?.preferred_username,
    );

    await checkSession();
    formData.append("session_id", sessionId.sessionId);

    const response = await axios.request({
      method: isRFPAPI
        ? apiMapper.rfpChatBotAPI.method
        : apiMapper.chatBotMessage.method,
      url: isRFPAPI
        ? apiMapper.rfpChatBotAPI.endpoint
        : apiMapper.chatBotMessage.endpoint,
      data: formData,
    });

    const data =
      typeof response?.data === "string"
        ? response?.data
        : response?.data?.response ||
          "There is some issue in processing the document. Please try again.";

    if (data !== "") {
      addMessageToList({
        content: data,
        firstMsg: false,
        createdDateTime: new Date(),
        messageType: "SERVER",
      });
    }

    chatInput.current = new FormData();
    setIsLoading(false);
  };

  const handleEnterClick = (e: any) => {
    if ((e.key === "Enter" && !e.shiftKey) || e.type === "click") {
      e.preventDefault();
      if (!isLoading && !isDisabled) {
        if (messageInput !== "") {
          setEnter(true);
          fetchMessages();
          setMessageInput("");
        }
      }
    }
  };

  // useEffect(() => {
  //   if (messageInput !== "") setIsDisabled(false);
  //   else setIsDisabled(true);
  // }, [messageInput]);

  const [inputPlaceholder, setInputPlaceholder] = useState("Type a message...");

  useEffect(() => {
    if (isLoading) {
      setIsDisabled(true);
      setInputPlaceholder("Please wait...");
    } else if (isRFPAPI && !enter) {
      setIsDisabled(true);
      setInputPlaceholder("Upload a document to get started...");
    } else {
      setIsDisabled(false);
      setInputPlaceholder("Type a message...");
    }
  }, [isRFPAPI, isLoading, isDisabled]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  const tabs = ["Assets assistant", "Proposal assistant"];

  const handleTabChange = (event: any, newValue: number) => {
    switchSession(event.target.textContent === tabs[1]);
    setActiveTab(newValue);
  };

  return (
    <>
      <div className={classes["container"]}>
        <div className={classes["chatbox"]}>
          <div className={classes["top-bar"]}>
            <img
              style={{
                width: "30px",
                height: "30px",
              }}
              src={chat}
              alt="chat-icon"
            />
            <Typography className={classes["top-bar-heading"]} color={"#fff"}>
              AI & Data assets hub assistant
            </Typography>
            <span className={classes["badge"]}>BETA</span>
            <div className={classes["top-bar-actions"]}>
              <IconButton sx={{ color: "#fff" }}>
                {/* <IconArrowsMaximize
                  onClick={props.expandModal}
                ></IconArrowsMaximize> */}
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <CloseIcon onClick={props.handleChatClose} />
              </IconButton>
            </div>
          </div>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="chatbot-tabs"
            className={classes["chatbox-tabs"]}
          >
            {tabs.map((tab) => {
              return <Tab label={tab} key={_.snakeCase(tab)} />;
            })}
          </Tabs>
          {enter && (
            <div className={classes["middle"]}>
              {messageList.map((message) => (
                <div
                  className={
                    message.messageType === "SERVER"
                      ? classes["incoming"]
                      : classes["outgoing"]
                  }
                >
                  {message.content && <Markdown>{message.content}</Markdown>}
                  {message.attachments && <IncomingFileView attachments={message.attachments} />}
                  <span className={classes["time"]}>
                    {dayjs(message.createdDateTime).format("hh:mm A")}
                  </span>
                </div>
              ))}
              <div ref={messagesEndRef}></div>
            </div>
          )}
          {!enter && (
            <div className={classes["middle"]}>
              <Typography
                align="center"
                mx={1}
                mt={1}
                mb={2}
                fontSize={13}
                p={1}
              >
                {isRFPAPI
                  ? "Hello! I'm here to help you with capabilities recommendations for your RFP. Please upload the RFP document to begin with and ask questions or switch tabs to inquire about capabilities or assets."
                  : "Hello! I'm here to assist you with any questions about AI & Data assets hub or recommend capabilities for your sales proposal. Let's get started!"}
              </Typography>
              <Typography variant="body2" mx={1}>
                How to use:
              </Typography>
              {isRFPAPI ? (
                <>
                  <ul className={classes["chat-infolist"]}>
                    <li>
                      <strong>Upload RFP document</strong>:
                      <ul>
                        <li>
                          <strong>Objective</strong>: Get capabilities
                          recommendations based on the details in the RFP
                          document
                        </li>
                        <li>
                          <strong>How</strong>:
                          <ul>
                            <li>
                              Upload RFP document (.docx, .pdf) using the paper
                              clip icon below to get capabilities
                              recommendations for your proposal
                            </li>
                            <li>Ask any follow-up questions</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className={classes["chat-spacer"]}></div>
                  <Typography
                    p={1}
                    m={1}
                    fontSize={11}
                    sx={{
                      backgroundColor: "#DDEFE8",
                      borderRadius: 1,
                    }}
                  >
                    Please switch to "<strong>Assets assistant</strong>" tab to
                    seek information regarding capabilities available on AI &
                    Data assets hub.
                  </Typography>
                </>
              ) : (
                <>
                  <ul className={classes["chat-infolist"]}>
                    <li>
                      <strong>Ask questions</strong>:
                      <ul>
                        <li>
                          <strong>Objective</strong>: Get information about the
                          capabilities hosted on the assets hub
                        </li>
                        <li>
                          <strong>How</strong>: Simply type your query in the
                          chat box, for example, "What are the features of
                          Data profiler?"
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className={classes["chat-spacer"]}></div>
                  <Typography
                    p={1}
                    m={1}
                    fontSize={11}
                    sx={{
                      backgroundColor: "#DDEFE8",
                      borderRadius: 1,
                    }}
                  >
                    Please switch to "<strong>Proposal assistant</strong>" tab
                    to seek information regarding capabilities pertinent to the
                    proposal/RFP document you're working on.
                  </Typography>
                </>
              )}
            </div>
          )}
          <div className={classes["bottom-bar"]}>
            <div className={classes["bottom-bar-chat"]}>
              <input
                type="text"
                placeholder={inputPlaceholder}
                className={classes["chat-textbox"]}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={handleEnterClick}
                value={messageInput}
                disabled={isDisabled}
              />

              {(!isRFPAPI || enter) && (
                <button
                  type="submit"
                  disabled={isLoading}
                  className={classes["send-button"]}
                  onClick={handleEnterClick}
                >
                  <IconSend2 />
                </button>
              )}
              {isRFPAPI && (
                <button
                  type="submit"
                  disabled={isLoading}
                  className={classes["send-button"]}
                  onClick={onTriggerFileUpload}
                >
                  <IconPaperclip />
                </button>
              )}

              <input
                type="file"
                ref={uploadFileInputRef}
                onChangeCapture={onFileUpload}
                accept=".pdf, .docx"
                hidden
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
