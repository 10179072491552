import pdfImage from "./../assets/images/pdfImage.png";
import xlsImage from "./../assets/images/xlsImage.png";
import pptImage from "./../assets/images/pptImage.png";

const ImgMapper: any = {
  xlsx: xlsImage,
  pdf: pdfImage,
  pptx: pptImage
};

export default ImgMapper;
