import { createBrowserRouter, Outlet } from "react-router-dom";
import Root from "../pages/Root";

import { LogoutPage } from "../pages/LogoutPage/LogoutPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/admin/insights",
        lazy: async () => {
          const Insight = await import("../pages/Admin/Insight");
          return { Component: Insight.default };
        },
      },
      {
        path: "/admin/enquiry",
        lazy: async () => {
          const Listing = await import("../pages/Admin/Enquiry/Listing");
          return { Component: Listing.default };
        },
      },
      {
        path: "/admin/enquiry/:id",
        lazy: async () => {
          const Details = await import("../pages/Admin/Enquiry/Details");
          return { Component: Details.default };
        },
      },
      {
        path: "/admin/intake",
        lazy: async () => {
          const Listing = await import("../pages/Admin/Intake/Listing");
          return { Component: Listing.default };
        },
      },
      {
        path: "/admin/intake/:id",
        lazy: async () => {
          const Details = await import("../pages/Admin/Intake/Details");
          return { Component: Details.default };
        },
      },
      {
        path: "/logout",
        element: <LogoutPage />,
      },
      {
        path: "",
        element: <Outlet />,
        children: [
          {
            path: "",
            lazy: async () => {
              const AssetLandingPage = await import(
                "../pages/AssetLandingPage"
              );
              return { Component: AssetLandingPage.default };
            },
            children: [
              {
                path: "/intake/new",
                lazy: async () => {
                  const Details = await import("../pages/IntakeForm");
                  return { Component: Details.default };
                },
              },
              {
                path: "/intake/listing",
                lazy: async () => {
                  const Listing = await import("../pages/Admin/Intake/Listing");
                  return { Component: Listing.default };
                },
              },
              {
                path: "/playbook",
                lazy: async () => {
                  const Playbook = await import("../pages/Playbook");
                  return { Component: Playbook.default };
                },
              },
              {
                path: "/faqs",
                lazy: async () => {
                  const FaqsView = await import("../pages/FaqsView");
                  return { Component: FaqsView.default };
                },
              },
              {
                path: "/feedback",
                lazy: async () => {
                  const { FeedbackForm } = await import(
                    "../pages/FeedbackForm"
                  );
                  return { Component: FeedbackForm };
                },
              },
              {
                path: "/search",
                lazy: async () => {
                  const SearchPage = await import("../pages/SearchPage");
                  return { Component: SearchPage.default };
                },
              },
              {
                path: "/digital-blueprint",
                lazy: async () => {
                  const DigitalBlueprint = await import("../pages/DigitalBluePrintPage");
                  return { Component: DigitalBlueprint.default };
                },
              },
            ],
          },
          {
            path: "/digital-blueprint/:id",
            lazy: async () => {
              const DigitalBluePrintDetails = await import("../pages/DigitalBluePrintDetails");
              return { Component: DigitalBluePrintDetails.default };
            },
          },
          {
            path: "/intake/:id",
            lazy: async () => {
              const Details = await import("../pages/Admin/Intake/Details");
              return { Component: Details.default };
            },
          },
          {
            path: `/:tabId`,
            lazy: async () => {
              const AssetLandingPage = await import(
                "../pages/AssetLandingPage"
              );
              return { Component: AssetLandingPage.default };
            },
          },
          {
            path: "/:tabId/category/:category",
            lazy: async () => {
              const CategoryPage = await import(
                "../pages/CategoryPage/CategoryPage"
              );
              return { Component: CategoryPage.default };
            },
          },
        ],
      },

      {
        path: "/:tabId/category/:category/wrapper/:wrapper",
        lazy: async () => {
          const AssetWrapper = await import("../pages/AssetWrapperPage");
          return { Component: AssetWrapper.default };
        },
      },
      {
        path: "/:tabId/category/:category/wrapper/:wrapper/capability/:capability",
        lazy: async () => {
          const Capability = await import("../pages/Capability");
          return { Component: Capability.default };
        },
      },
      {
        path: "/capability/:capability",
        lazy: async () => {
          const Capability = await import("../pages/Capability");
          return { Component: Capability.default };
        },
      },
    ],
  },

  {
    path: "/login",
    lazy: async () => {
      const LoginPage = await import("../pages/LoginPage/LoginPage");
      return { Component: LoginPage.default };
    },
  },
]);

export default router;
